import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/OurImpact/banner.jpg';
import OurVision from '../../images/landingPage/OurImpact/OurVision.jpg';
import Section3 from '../../images/landingPage/OurImpact/section3.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import HubspotForm from 'react-hubspot-form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './OurImpact.module.scss';

function OurImpact() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Our </span> Impact</h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Go start up go uses its global reach and local presence to build stronger, more resilient and more equitable communities where everyone can thrive. We are committed to promoting entrepreneurship as a means of helping people out of need.</p>
                        <div className='col-sm-12' data-aos="fade-up"><Link to="/get-involved" className='btn--primary me-2' role='button' aria-label='Make an Impact'>Make an Impact</Link> </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end'>
                        <img src={Banner} alt='Slider' data-aos="fade-up" className='w-100 h-auto ps-lg-5 ps-xs-0'/>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <img src={OurVision} alt='Our vision is simple' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">Our vision is simple</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up">Through Go Start Up Go, we collaborate with individuals, businesses, and organizations to address the challenges facing entrepreneurs in Nigeria and Africa as a whole. Our mission is to empower entrepreneurs with the tools and resources they need to build and grow successful businesses. We achieve this through a combination of mentorship, education, and funding opportunities.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section3} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Innovation, Impact, and Entrepreneurship At Go Startup Go</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">At Go Start Up Go Foundation, we are passionate about the transformative power of entrepreneurship. We are dedicated to empowering individuals and communities by fostering a culture of entrepreneurship that encourages creativity, innovation, and ethical business practices, Our mission is to support aspiring entrepreneurs to start businesses that not only create job opportunities but also bring about positive social change. We believe that entrepreneurship is a vital tool for economic development and that by supporting entrepreneurs, we can build a more prosperous and sustainable future for all.</p>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={Section3} alt='Innovation, Impact, and Entrepreneurship At Go Startup Go' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section4} container-fluid bg--grey1 py-5`} id="newsletter">
            <div className={`container py-5 text-start br--20`}>
                <div className='row'>
                    <div className={`${styles.section4Inner} shadow-sm col-sm-12 col-xs-12 bg-white p-5 br--20`}>
                        <h3 className='fs--30 fw--600 pb-1 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Newsletter Sign-Up </h3>
                        <p className='fs--18 text--white3' data-aos="fade-up">Stay updated on how your contributions are making a difference. Sign up for our newsletter to receive stories of success, updates on new programs, and opportunities to get involved.</p>
                        <div data-aos="fade-up">
                            <HubspotForm
                                portalId='23639501'
                                formId='07bd4aff-5810-45d7-b4c5-c001794afad8'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default OurImpact