import React from 'react';
import { Link, NavLink , useNavigate } from "react-router-dom";
import { Container, Nav, Navbar } from 'react-bootstrap';
import Logo from '../../../images/landingPage/gsglogo.png';

const Header = () => {
  let navigate = useNavigate()
  return (
      <Navbar expand="lg" fixed="top" className='navbar--container py-2 bg-white shadow-sm'>
        <Container className='header--container'>
          <Navbar.Brand className='me-md-auto p-0'>
            <Link to='/'><img src={Logo} alt='GoStartupGo' className='logo' width='150'/></Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <div className='d-flex w-100 m-menu'>
            <Navbar.Collapse id="navbarScroll" className='justify-content-end'>
              <Nav
                className="my-2 my-lg-0"
                navbarScroll
              >
                <NavLink  to="/" activeClassName="active" className={"me-1 nav-NavLink "}>Home</NavLink>
                <NavLink  to="/about-us" activeClassName="active" className={"me-1 nav-NavLink "}>About Us</NavLink>
                <NavLink  to="/what-we-do" activeClassName="active" className={"me-1 nav-link"}>What we do</NavLink >
                <NavLink  to="/success-stories" activeClassName="active" className={"me-1 nav-link"}>Success Stories</NavLink >
                <NavLink  to="/donate" activeClassName="active" className={"me-1 nav-link"}>Donate</NavLink >
                <NavLink  to="/my-choices" activeClassName="active" className={"me-1 nav-link"}>My Choice</NavLink >
                <NavLink  to="/get-involved" activeClassName="active" className={"me-1 nav-link"}>Get Involved</NavLink>
                <NavLink  to="/shop" activeClassName="active" className={"me-1 nav-link"}>Shop</NavLink>
                <NavLink  to="/press-releases" activeClassName="active" className={"me-1 nav-link"}>Press Releases</NavLink>
                <NavLink  to="/contact-us" activeClassName="active" className={"me-1 nav-link"}>Contact Us</NavLink>
              </Nav>
            </Navbar.Collapse>
            {/* <div className='d-flex button--container'>
              <button onClick={() => navigate('/sign-up')} className='btn--outline--signup border-0 me-2' role='button' aria-label='Sign Up'>Sign Up</button>
              <button onClick={() => navigate('/sign-in')} className='btn--outline--signin border-0' role='button' aria-label='Sign In'>Sign In</button>
            </div> */}
          </div>
        </Container>
      </Navbar>
  )
}

export default Header;