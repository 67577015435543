import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/GetInvolved/banner.jpg';
import Section2 from '../../images/landingPage/GetInvolved/Corporate-Sponsorships.jpg';
import Section3 from '../../images/landingPage/GetInvolved/Volunteer-Opportunities.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import HubspotForm from 'react-hubspot-form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Partner.module.scss';
import { NavHashLink } from 'react-router-hash-link';

function GetInvolved() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -220;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Get </span> Involved</h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Our approach is centered on ensuring that the businesses we enable and empower are truly setup for success. ​</p>
                        <div className='col-sm-12' data-aos="fade-up"><Link to="/contact-us" className='btn--primary me-lg-2 me-xs-0' role='button' aria-label='Make an Impact'>Make an Impact</Link> </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
                        <img src={Banner} alt='Slider' className='w-100 height420 br--30' data-aos="fade-up" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2 position-relative rightEffect'>
                        <img src={Section2} alt='Corporate Sponsorships' className='w-100 height420 br--30' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">Corporate Sponsorships</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up">Partner with us to make a lasting impact. By sponsoring our entrepreneurs, your company demonstrates a commitment to social responsibility and sustainable business development. Sponsorship packages are available for businesses looking to make a tangible difference.</p>
                        <div className='col-sm-12 ps-lg-5 ps-xs-0' data-aos="fade-up"> <Link to="/contact-us" className='btn--secondary px-5' role='button' aria-label='Join us'>Join us</Link></div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section31} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Volunteer Opportunities </h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Join us as a mentor or ambassador. Whether you’re an experienced entrepreneur or simply passionate about helping others, you can make a real difference by volunteering your time and expertise. </p>
                        <div className='col-sm-12' data-aos="fade-up"> <Link to="/contact-us" className='btn--secondary px-5' role='button' aria-label='Join us'>Join us</Link></div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
                        <img src={Section3} alt='Volunteer Opportunities' className='w-100 height420 br--30' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section5} container-fluid bg--grey1 py-5`} id="findOutMore">
            <div className={`container py-5 text-start br--20`}>
                <div className='row'>
                    <div className={`${styles.section5Inner} shadow-sm col-sm-12 col-xs-12 bg-white p-5 br--20`}>
                        <h3 className='fs--30 fw--600 pb-1 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Newsletter Sign-Up </h3>
                        <p className='fs--18 text--white3' data-aos="fade-up">Stay updated on how your contributions are making a difference. Sign up for our newsletter to receive stories of success, updates on new programs, and opportunities to get involved.</p>
                        <div data-aos="fade-up">
                            <div className={`${styles.findOutMoreRight} pt-4 h-100`}>
                                <HubspotForm
                                    portalId='23639501'
                                    formId='07bd4aff-5810-45d7-b4c5-c001794afad8'
                                    onSubmit={() => console.log('Submit!')}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default GetInvolved