import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../images/landingPage/gsglogo.png';
import AddressIcon from '../../../images/landingPage/map.svg';
import MobileIcon from '../../../images/landingPage/mobile.svg';
import EmailIcon from '../../../images/landingPage/email.svg';
import ArrowRight from '../../../images/landingPage/ArrowRight.svg';
const Footer = () => {
    return (
        <footer className='bg-white py-lg-5 py-xs-1 border-bottom'>
            <section className='container footer--top-container pt-lg-3 pt-xs-1'>
                <div className='row'>
                    <div className='col-sm-4 col-12 fontPoppins'>
                        <img src={Logo} alt="GoStartupGo" className='mb-4 w-50 h-auto' />
                        <p className='fs--20 fw--600'>Changing lives, <br />one <span className='text--primary'>business</span> at a time</p>
                    </div>
                    <div className='col-sm-5 col-12 fontPoppins'>
                        <h5 className='fs--16 fw--600  mb-2 text-black'>Connect With Us</h5>
                        <p className='fw--600 fs--16 mb-2  text-black'>US - Address</p>
                        <div className='fs--16 mb-1 lh-25 text--white3 d-flex align-items-start'><img src={AddressIcon} alt='Address' className='me-2 pt-1' /> 989 Senate Drive, <br/>Dayton, OH 45459</div>
                        <div className='pb-3 d-flex align-items-start'><img src={MobileIcon} alt='Mobile' className='me-2' /> <Link href='tel:+19376198397' className='text--white3 text-decoration-none link--hover'>+1 9376198397</Link></div>
                        <p className='fw--600 fs--16 mb-2  text-black'>Nigeria - Address</p>
                        <div className='fs--16 mb-1 lh-25 text--white3 d-flex align-items-start'><img src={AddressIcon} alt='Address' className='me-2 pt-1' />No: 1 Okoro - Otun Avenue, <br />Oredo Local Government Area, Edo State.</div>
                        <div className='pb-2 d-flex align-items-start'><img src={MobileIcon} alt='Mobile' className='me-2' /><Link href='tel:+2349021309729' className='text--white3 link--hover text-decoration-none'>+234-902-130-9729</Link></div>
                        <div className='pb-3 d-flex align-items-start'><img src={EmailIcon} alt='Email' className='me-2 pt-1' />
                            <Link to="mailto:info@gostartupgo.org" className='text--white3 text-decoration-none link--hover'>info@gostartupgo.org</Link>
                        </div>
                    </div>
                    <div className='col-sm-3 col-12 fontPoppins'>
                        <h5 className='fs--16 fw--600 text-black mb-3'>Useful Links</h5>
                        <ul className="ps-0 list-container">
                            <li className='list-unstyled pb-3'><Link to="/about-us"><img src={ArrowRight} alt='Arrow Right' className='pe-2' width="18"/> About Us</Link></li>
                            <li className='list-unstyled pb-3'><Link to="/what-we-do"><img src={ArrowRight} alt='Arrow Right' className='pe-2' width="18"/> What we do</Link></li>
                            <li className='list-unstyled pb-3'><Link to="/donate"><img src={ArrowRight} alt='Arrow Right' className='pe-2' width="18"/> Donate</Link></li>
                            <li className='list-unstyled pb-3'><Link to="/my-choices"><img src={ArrowRight} alt='Arrow Right' className='pe-2' width="18"/> My Choice</Link></li>
                            <li className='list-unstyled pb-3'><Link to="/get-involved"><img src={ArrowRight} alt='Arrow Right' className='pe-2' width="18"/> Get Involved</Link></li>
                            <li className='list-unstyled pb-3'><Link to="/shop"><img src={ArrowRight} alt='Arrow Right' className='pe-2' width="18"/> Shop</Link></li>
                            <li className='list-unstyled pb-3'><Link to="/contact-us"><img src={ArrowRight} alt='Arrow Right' className='pe-2' width="18"/> Contact Us</Link></li>

                        </ul>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer;
