import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Section3 from '../../images/landingPage/press-releases/blog3.jpg';
import Section4 from '../../images/landingPage/press-releases/About-the-GoStartupGo-Foundation.jpg';
import Section6 from '../../images/landingPage/press-releases/Building-Sustainable-Businesses.jpg';
import SaSquared from '../../images/landingPage/press-releases/SaSquared.jpg';
import CircleCheckIcon from '../../images/landingPage/CircleCheck.svg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AxiosService from '../../service/ApiService';
import { toast } from 'react-toastify';
import GetInTouch from '../../images/landingPage/GetInvolved/GetInTouch.jpg';
import 'aos/dist/aos.css';
import styles from './News.module.scss';

function Blog3() {    
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [showContact, setShowContact] = useState(true);

    const formik = useFormik({
        initialValues: {
          yourName : "",
          email : "",
          phoneNumber : "",
          website: ""
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
          yourName : Yup.string()
          .required("Please enter the name"),
          
          email : Yup.string()
          .required("Please enter the email"),

          phoneNumber : Yup.string()
          .required("Please enter the phone number")

        }),
        onSubmit: (values) => {
            handleGetInvolved(values);
        },
    });

    const handleGetInvolved = async (values) => {
        try {
            let res = await AxiosService.post('users/getInvlovedMail', values);
            if(res.data.statusCode === 200){
                toast.success(res.data.message)
              }
        } catch (error) {
            toast.error(
                error.response.data.message
                  ? error.response.data.message
                  : error.response.data.message[0]
              );
        }
    };

    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);

    return <>
        <Header />
        <section className={`${styles.blogSection2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10'>
                        <div className='position-relative'>
                            <img src={Section3} alt='GoStartupGo partners with SaSquared Ventures to fund micro entrepreneurs' className='w-100 h-auto br--10' data-aos="fade-right" />
                            <div className={`${styles.date} bg--primary position-absolute p-2  br--10 text-white`}>September 15th, 2024</div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                        <div className={`${styles.date} d-inline-block mb-3 ms-lg-5 ms-xs-0 bg--secondary px-4 py-2  br--10 text-white`} data-aos="fade-up">Partnerships</div>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">GoStartUpGo Foundation Partners with SasquaredVentures Limited to Empower Entrepreneurs in Nigeria</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up"><span className='text--primary'>Atlanta, GA</span><br/>GoStartUpGo Foundation, a U.S.-based 501(c)(3) nonprofit focused on fostering entrepreneurship in developing countries, proudly announces its partnership with <strong>SasquaredVentures Limited</strong>, a leading Nigerian business solutions provider, to manage its entrepreneurship programs in Nigeria. This partnership will enable GoStartUpGo’s mission of empowering entrepreneurs in Africa by providing them with the tools, mentorship, and financial resources needed to build sustainable businesses.  </p>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up"><strong>SasquaredVentures Limited</strong>, a trusted name in business development and financial management in Nigeria, will spearhead the program's local operations. As part of this collaboration, SasquaredVentures will oversee the day-to-day management of GoStartUpGo’s Nigerian entrepreneurship program, which includes the disbursement of funds, program administration, and tracking of the entrepreneurs' progress.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section5} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                   <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">The Program: Building Sustainable Businesses </h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">The program will provide Nigerian entrepreneurs with access to:</p>
                        <ul className='ps-1'>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--16 text--white3 lh-30 mb-0'>Intensive business training covering areas such as financial literacy, product development, and marketing.</p>
                                </div> 
                                
                            </li>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--16 text--white3 lh-30 mb-0'>Ongoing mentorship from experienced business leaders.</p>
                                </div> 
                            </li>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--16 text--white3 lh-30 mb-0'>Funding and grants to help entrepreneurs start and grow their businesses.</p>
                                </div> 
                            </li>
                        </ul>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up"><strong>SasquaredVentures’ Role in the Partnership</strong> SasquaredVentures will play a key role in ensuring that all funds are allocated appropriately and that the program is running efficiently. By leveraging their expertise in the Nigerian market, SasquaredVentures will provide crucial oversight, ensuring the success and sustainability of the businesses that GoStartUpGo supports. This partnership allows GoStartUpGo Foundation to focus on expanding its reach, while SasquaredVentures handles the local execution and management.</p>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up"><strong>Empowering Entrepreneurs for a Stronger Future</strong> The partnership comes at a critical time, as entrepreneurship in Nigeria continues to be a key driver of economic development and job creation. With this collaboration, GoStartUpGo Foundation aims to help more entrepreneurs in Nigeria overcome obstacles, access the resources they need, and build businesses that positively impact their communities.<br/>
                        “We are excited to partner with SasquaredVentures Limited to bring our entrepreneurship program to life in Nigeria,” said [Your Name], Founder and Executive Director of GoStartUpGo Foundation. “Their expertise and deep knowledge of the local market will ensure that we can help more entrepreneurs build successful businesses and strengthen their communities."
                        </p>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={Section6} alt='Building Sustainable Businesses' className='w-100 height420 br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10'>
                        <div className='position-relative'>
                            <img src={Section4} alt='About GoStartUpGo Foundation' className='w-100 height420 br--10' data-aos="fade-right" />
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">About GoStartUpGo Foundation </h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up">GoStartUpGo Foundation is a nonprofit organization dedicated to empowering entrepreneurs in developing countries through training, mentorship, and access to funding. By helping people build sustainable businesses, GoStartUpGo is committed to creating lasting economic and social change.  </p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section5} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                   <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">About SasquaredVentures Limited </h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">SasquaredVentures Limited is a Nigerian company that provides innovative business solutions, financial management, and consulting services to help businesses grow and thrive. With a deep understanding of the Nigerian market and a commitment to fostering entrepreneurship, SasquaredVentures is the ideal partner to manage GoStartUpGo’s programs in Nigeria.</p>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={SaSquared} alt='About the GoStartupGo Foundation' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className='container'>
            <div className={` ${styles.formSection}`}>
                <div className={`row`}>
                   <div className='col-sm-6 col-xs-12 py-5 px-lg-5 px-xs-0'>
                        <img src={GetInTouch} alt='get involved' className='w-100 h-auto br--20'/>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-4'>
                        <h2 className='fs--24 pt-5 pb-3 px-4 text-black fontPoppins lh-25 pe-lg-5 pe-xs-0' data-aos="fade-up"><span>For more information, please contact </span></h2>
                        <div data-aos="fade-up">
                            <div className={`px-4 h-100`}>
                                {showContact && (
                                    <Form className='row' onSubmit={formik.handleSubmit}>
                                        <Form.Group className="col-sm-6 mb-4" controlId="yourName" data-aos="fade-up">
                                            <Form.Label>Your name</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Your name"
                                                name="yourName"
                                                onChange={formik.handleChange}
                                                onBlur = {formik.handleBlur}
                                                value={formik.values.yourName} />
                                            {
                                                formik.errors.yourName && formik.touched.yourName ? (
                                                <p className="text--danger">{formik.errors.yourName} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-6 mb-4" controlId="email" data-aos="fade-up">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                onChange={formik.handleChange}
                                                onBlur = {formik.handleBlur}
                                                value={formik.values.email} />
                                            {
                                                formik.errors.email && formik.touched.email ? (
                                                <p className="text--danger">{formik.errors.email} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-6 mb-4" controlId="phoneNumber" data-aos="fade-up">
                                            <Form.Label>Phone number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="phoneNumber"
                                                placeholder="Phone number"
                                                onChange={formik.handleChange}
                                                onBlur = {formik.handleBlur}
                                                value={formik.values.phoneNumber} />
                                            {
                                                formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                                                <p className="text--danger">{formik.errors.phoneNumber} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-6 mb-4" controlId="website" data-aos="fade-up">
                                            <Form.Label>Website</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="website"
                                                placeholder="Website"
                                                onChange={formik.handleChange}
                                                onBlur = {formik.handleBlur}
                                                value={formik.values.website} />
                                            {
                                                formik.errors.website && formik.touched.website ? (
                                                <p className="text--danger">{formik.errors.website} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-4 offset-sm-4 col-xs-12 mb-3 mt-3" controlId="country" data-aos="fade-up">
                                            <button type="submit" className='btn--primary w-100 py-2 border-0' aria-label='Submit'>
                                            Submit</button>
                                        </Form.Group>
                                    </Form>
                                )}
                                <div className="text-start pt-4">
                                    {showSuccessMessage && (
                                        <div className='text-center'>
                                            <h4 className='fs-24 fw-bold text-white'>Your message was submitted!</h4>
                                            <p className='fs-19 text-white mb-5'>We have received your message, a member of our Team will be in contact</p>
                                            <Link href="/" className='fs-16 text--primary link--hover'>Learn more about GoStartupGo</Link>
                                        </div>
                                    )}
                                    {showFailureMessage && (
                                        <p className="text--danger">
                                            Oops! Something went wrong, please try again.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default Blog3