import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Section6 from '../../images/landingPage/press-releases/blog3.jpg';
import Spacepointe from '../../images/landingPage/press-releases/spacepointe.png';
import CircleCheckIcon from '../../images/landingPage/CircleCheck.svg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './News.module.scss';

function Blog2() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return <>
        <Header />
        <section className={`${styles.blogSection2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10'>
                        <div className='position-relative rightEffect2'>
                            <img src={Spacepointe} alt='About SpacePointe Inc.' className='w-100 h-auto br--30' data-aos="fade-right" />
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">About SpacePointe Inc.</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up">SpacePointe is a payments technology company with a mission to transform the point-of-sale for formal and informal sectors. Focused on inclusive technology, SpacePointe's platform enables thousands of businesses (globally) to do more by leveraging the power of the point-of-sale beyond just payment acceptance, with leading-edge tools that enables them to manage their business as well as access a plethora of revenue generation opportunities at the till. The business point of sale can be as simple as a QR code redemption terminal or as complex as a full business management suite of solutions. SpacePointe's innovative platforms drive business throughout the supply chain ecosystem from the FMCGs (CPGs) all the way to the Consumers. SpacePointe utilizes a combination of technology and key business partnerships in the ecosystem to deliver the required solutions that drive adoption for businesses.</p>
                    </div>
                </div>
            </div>
        </section>
        
        <section className={`${styles.section5} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                   <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">About the GoStartupGo Foundation</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">The vision of the GoStartupGo foundation is to encourage and enable successful business ownership for the people who are struggling to make a basic living in the face of very difficult economies.</p>
                        <ul className='ps-1'>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--18 text-black fw--600 mb-2'>Small businesses are crucial to every economy.</p>
                                    <p className='fs--16 text--white3 lh-35'>The vision of the GoStartupGo foundation is to encourage and enable successful business ownership for the people who are struggling to make a basic living in the face of very difficult economies.</p>
                                </div> 
                                
                            </li>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--18 text-black fw--600 mb-2'>Starting up a business does not automatically guarantee success.</p>
                                    <p className='fs--16 text--white3 lh-35'>At GoStartupGo, we focus primarily on activities that create entrepreneurs and set them up to start and succeed at running their businesses. To that effect, we train entrepreneurs and partner with 3rd parties to provide tools, services, and funding to equip them throughout their business journey. <span className='text-black'>Learn more at:</span> <Link to='https://gostartupgo.org/' className='text--primary link--hover text-decoration-none'>GoStartupGo.org</Link></p>
                                </div> 
                                
                            </li>
                        </ul>
                    </div>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0'>
                        <div className='position-relative rightEffect2'>
                            <img src={Section6} alt='About the GoStartupGo Foundation' className='w-100 h-auto br--30' data-aos="fade-right" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default Blog2