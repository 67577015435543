import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/WhatWeDo/banner.jpg';
import OurApproach from '../../images/landingPage/WhatWeDo/OurApproach.jpg';
import StrategicPartners from '../../images/landingPage/WhatWeDo/StrategicPartners.jpg';
import SpecializedCertification from '../../images/landingPage/WhatWeDo/SpecializedCertification.jpg';
import Mentorship from '../../images/landingPage/WhatWeDo/Mentorship.jpg';
import FundingPrograms from '../../images/landingPage/WhatWeDo/FundingPrograms.jpg';
import ITEPModel from '../../images/landingPage/WhatWeDo/itep.jpeg';
import Modal from 'react-bootstrap/Modal';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import HubspotForm from 'react-hubspot-form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './WhatWeDo.module.scss';


function WhatWeDo() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>What</span> We Do</h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Our approach is centered on ensuring that the businesses we enable and empower are truly setup for success. It is not just about starting up. It is about understanding how to do business and being equipped with all the required tools to succeed.</p>
                        <div className='col-sm-12' data-aos="fade-up"><Link to="/get-involved" className='btn--primary me-2' role='button' aria-label='Make an Impact'>Make an Impact</Link> </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
                        <img src={Banner} alt='Slider' className='w-100 height420 br--30' data-aos="fade-up" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <img src={OurApproach} alt='Entrepreneur Training' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Entrepreneur Training</h3>
                        <p className='fs--18 pb-1 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Our training programs equip aspiring entrepreneurs with the tools and knowledge they need to turn ideas into thriving businesses. Covering topics like financial management, marketing, and product development, we provide everything they need to start strong.</p>
                    </div>
                    <Modal show={show} onHide={handleClose} centered size="lg">
                        <Modal.Header closeButton className={`${styles.modalHeader} border-0 pb-0`}>
                        </Modal.Header>
                        <Modal.Body className='text-center p-0'>
                            <img src={ITEPModel} alt='ITEP Model' className='w-100 h-auto br--10' />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </section>
        <section className={`${styles.section5} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Mentorship</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35 pe-lg-5 pe-xs-0' data-aos="fade-up">Every entrepreneur in our program is paired with a mentor who provides guidance, support, and expertise. These mentors are experienced business leaders committed to helping entrepreneurs navigate challenges and scale their businesses.</p>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={Mentorship} alt='Funding Programs' className='w-100 height420 br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section6} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-12 col-xs-12' >
                        <div className={`${styles.section6Inner} position-relative p-5 text-center bgGradient--primary br--999`}>
                            <p className='fs--44 lh-55 fw--600 fontPoppins text-white mb-2' data-aos="fade-up">We believe in making friends, not contacts. We believe in giving first, not taking. We believe in helping others before helping yourself. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section7} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <img src={StrategicPartners} alt='Access to Funding' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Access to Funding</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35 pe-lg-5 pe-xs-0' data-aos="fade-up">Entrepreneurs need capital to succeed, and GoStartUpGo helps provide that through grants, microloans, and business development support. Every dollar goes toward setting up sustainable, community-focused businesses.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section8} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Become a Sponsor</h3>
                        <p className='fs--18 text-black fontPoppins lh-35 pe-lg-5 pe-xs-0' data-aos="fade-up">Directly impact an entrepreneur's future by sponsoring their journey. Choose from the following sponsorship levels: </p>
                        <ul className='ps-5'>
                            <li className='mb-2' data-aos="fade-up"><strong>$100: </strong>Provides an entrepreneur with vital training.</li>
                            <li className='mb-2' data-aos="fade-up"><strong>$250: </strong>Helps fund essential startup materials.</li>
                            <li data-aos="fade-up"><strong>$500: </strong>Fully sponsors an entrepreneur’s journey from training to business launch.</li>
                        </ul>
                        <p data-aos="fade-up">All sponsors receive personalized updates on the entrepreneur they are helping, including photos, progress reports, and success stories.</p>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={SpecializedCertification} alt='Specialized Certification' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section9} container-fluid bg--grey1 py-5`}>
            <div className={`container py-5 text-start br--20`}>
                <div className={`${styles.section9Inner} shadow-sm col-sm-12 col-xs-12 bg-white p-5 br--20`}>
                    <h3 className='fs--30 fw--600 pb-1 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Newsletter Sign-Up </h3>
                    <p className='fs--18 text--white3' data-aos="fade-up">Stay updated on how your contributions are making a difference. Sign up for our newsletter to receive stories of success, updates on new programs, and opportunities to get involved.</p>
                    <div data-aos="fade-up">
                        <div className={`${styles.findOutMoreRight} pt-4 h-100`}>
                            <HubspotForm
                                portalId='23639501'
                                formId='07bd4aff-5810-45d7-b4c5-c001794afad8'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default WhatWeDo