import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import GetInTouch from '../../images/landingPage/GetInvolved/GetInTouch.jpg';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './GetInvolved.module.scss';
import AxiosService from '../../service/ApiService';
import { toast } from 'react-toastify';
import HubspotForm from 'react-hubspot-form';

function ContactUs() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const [showContact, setShowContact] = useState(true);

    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            email: "",
            contactNumber: "",
            city: "",
            country: ""
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            firstname: Yup.string()
                .required("Please enter the first name"),

            lastname: Yup.string()
                .required("Please enter the last name"),

            email: Yup.string()
                .required("Please enter the email"),

            contactNumber: Yup.string()
                .required("Please enter the contact number"),

            city: Yup.string()
                .required("Please enter the city"),

            country: Yup.string()
                .required("Please enter the country"),

        }),
        onSubmit: (values) => {
            handleGetInvolved(values);
        },
    });

    const handleGetInvolved = async (values) => {
        try {
            let res = await AxiosService.post('users/getInvlovedMail', values);
            if (res.data.statusCode === 200) {
                toast.success(res.data.message)
            }
        } catch (error) {
            toast.error(
                error.response.data.message
                    ? error.response.data.message
                    : error.response.data.message[0]
            );
        }
    };
    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-8 offset-sm-2 col-xs-12 text-center'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Get In Touch, </span></h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Thank you for joining us to make an impact, we are changing lives and building communities, one business at a time.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`container`} style={{ paddingBottom: '12rem'}}>
            <div className={`${styles.section2} ps-lg-4 ps-xs-0 py-lg-5 py-xs-0`}>
                <div className={`row`}>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--24 pt-5 pb-3 px-3 text-black fontPoppins lh-25 pe-lg-5 pe-xs-0' data-aos="fade-up"><span>Partnership and Sponsorship Inquiries </span></h2>
                        <p className='fs--16 pb-3 px-3 text-black fontPoppins lh-25  pe-lg-5 pe-xs-0' data-aos="fade-up">If you’re interested in partnering with GoStartUpGo or sponsoring one of our programs, we’d love to hear from you. Reach out to us for more information on how your contribution can create lasting change.</p>
                        <div data-aos="fade-up">
                            <div className={`px-3 h-100`}>
                                {showContact && (
                                    <Form className='row' onSubmit={formik.handleSubmit}>
                                        <Form.Group className="col-sm-6 mb-4" controlId="FirstName" data-aos="fade-up">
                                            <Form.Label>First name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="First name"
                                                name="firstname"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.firstname} />
                                            {
                                                formik.errors.firstname && formik.touched.firstname ? (
                                                    <p className="text--danger">{formik.errors.firstname} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-6 mb-4" controlId="LastName" data-aos="fade-up">
                                            <Form.Label>Last name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="lastname"
                                                placeholder="Last name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.lastname} />
                                            {
                                                formik.errors.lastname && formik.touched.lastname ? (
                                                    <p className="text--danger">{formik.errors.lastname} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-6 mb-4" controlId="email" data-aos="fade-up">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email} />
                                            {
                                                formik.errors.email && formik.touched.email ? (
                                                    <p className="text--danger">{formik.errors.email} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-6 mb-4" controlId="contactNumber" data-aos="fade-up">
                                            <Form.Label>Contact number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="contactNumber"
                                                placeholder="Contact number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.contactNumber} />
                                            {
                                                formik.errors.contactNumber && formik.touched.contactNumber ? (
                                                    <p className="text--danger">{formik.errors.contactNumber} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-6 mb-4" controlId="city" data-aos="fade-up">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="city"
                                                placeholder="City"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.city} />
                                            {
                                                formik.errors.city && formik.touched.city ? (
                                                    <p className="text--danger">{formik.errors.city} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-6 mb-4" controlId="country" data-aos="fade-up">
                                            <Form.Label>Country</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="country"
                                                placeholder="Country"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.country} />
                                            {
                                                formik.errors.country && formik.touched.country ? (
                                                    <p className="text--danger">{formik.errors.country} </p>
                                                ) : ""
                                            }
                                        </Form.Group>
                                        <Form.Group className="col-sm-4 offset-sm-4 col-xs-12 mb-3 mt-3" controlId="country" data-aos="fade-up">
                                            <button type="submit" className='btn--primary w-100 py-2 border-0' aria-label='Submit'>
                                                Submit</button>
                                        </Form.Group>
                                    </Form>
                                )}
                                <div className="text-start pt-4">
                                    {showSuccessMessage && (
                                        <div className='text-center'>
                                            <h4 className='fs-24 fw-bold text-white'>Your message was submitted!</h4>
                                            <p className='fs-19 text-white mb-5'>We have received your message, a member of our Team will be in contact</p>
                                            <Link href="/" className='fs-16 text--primary link--hover'>Learn more about GoStartupGo</Link>
                                        </div>
                                    )}
                                    {showFailureMessage && (
                                        <p className="text--danger">
                                            Oops! Something went wrong, please try again.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-5 px-lg-5 px-xs-0'>
                        <img src={GetInTouch} alt='get involved' className='w-100 h-auto br--20' />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section9} container-fluid bg--grey1 py-5`}>
            <div className={`container py-5 text-start br--20`}>
                <div className={`${styles.section9Inner} shadow-sm col-sm-12 col-xs-12 bg-white p-5 br--20`}>
                    <h3 className='fs--30 fw--600 pb-1 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Newsletter Sign-Up </h3>
                    <p className='fs--18 text--white3' data-aos="fade-up">Stay updated on how your contributions are making a difference. Sign up for our newsletter to receive stories of success, updates on new programs, and opportunities to get involved.</p>
                    <div data-aos="fade-up">
                        <div className={`${styles.findOutMoreRight} pt-4 h-100`}>
                            <HubspotForm
                                portalId='23639501'
                                formId='07bd4aff-5810-45d7-b4c5-c001794afad8'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section3} container-fluid py-5`}>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-10 offset-sm-1 col-xs-12 py-lg-5 py-xs-0 text-white text-center'>
                        <h4 className='fs--40 lh-55 mb-4 fw--400' data-aos="fade-up">To Become A Partner, send us an email</h4>
                        <p className='fs--20 mb-0 fw--400' data-aos="fade-up"><span className='bg--primary px-2 pb-1 br--20'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                        </svg></span> info@gostartupgo.org</p>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default ContactUs