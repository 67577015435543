import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/OurImpact/stories.png';
import OurVision from '../../images/landingPage/OurImpact/Entrepreneur-Spotlights.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import HubspotForm from 'react-hubspot-form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './OurImpact.module.scss';

function SuccessStories() {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  return <>
    <Header />
    <section className={`${styles.section1} container-fluid`}>
      <div className={`container py-lg-5 py-xs-0`}>

        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Success </span> Stories</h2>
            <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Go start up go uses its global reach and local presence to build stronger, more resilient and more equitable communities where everyone can thrive. We are committed to promoting entrepreneurship as a means of helping people out of need.</p>
            <div className='col-sm-12' data-aos="fade-up"><Link to="/get-involved" className='btn--primary me-2' role='button' aria-label='Make an Impact'>Make an Impact</Link> </div>
          </div>
          <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
            <img src={Banner} alt='Slider' data-aos="fade-up" className='br--30 w-100 height420' />
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section3} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row mb-5'>
          <div className='col-sm-12 text-black text-center'>
            <h2 className='fs--38 lh-45 mb-4 fontPoppins fw--600' data-aos="fade-right">Entrepreneur Spotlights</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2 position-relative rightEffect'>
            <img src={OurVision} alt='Entrepreneur Spotlights' className='w-100 height420 br--30' data-aos="fade-right" />
          </div>
          <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1 d-flex flex-column justify-content-center'>
            <div className='ps-lg-5 ps-xs-0 '>
              <div className={`${styles.section5Inner} position-relative p-4 m-mb-40`}>
                <p class="fs--18 lh-35 fontPoppins fst-italic text--white3 mb-2" data-aos="fade-up">Before joining GoStartUpGo, Sarah struggled to find resources to launch her eco-friendly product line. With support from the foundation, she secured the funding and mentorship needed to start her business. Today, Sarah employs 10 people in her community and is expanding her product reach across multiple cities. </p><p class="fs--18 text-end fontPoppins fst-italic fw--600 mb-4 pe-lg-5 pe-xs-0 aos-init" data-aos="fade-up">-- Sarah’s Story</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section4} container-fluid bg--grey1 py-5`} id="newsletter">
      <div className={`container py-5 text-start br--20`}>
        <div className='row'>
          <div className={`${styles.section4Inner} shadow-sm col-sm-12 col-xs-12 bg-white p-5 br--20`}>
            <h3 className='fs--30 fw--600 pb-1 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Newsletter Sign-Up </h3>
            <p className='fs--18 text--white3' data-aos="fade-up">Stay updated on how your contributions are making a difference. Sign up for our newsletter to receive stories of success, updates on new programs, and opportunities to get involved.</p>
            <div data-aos="fade-up">
              <HubspotForm
                portalId='23639501'
                formId='07bd4aff-5810-45d7-b4c5-c001794afad8'
                onSubmit={() => console.log('Submit!')}
                onReady={(form) => console.log('Form ready!')}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <Copyright />
  </>
}

export default SuccessStories