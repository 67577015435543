import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/WhoWeAre/banner1.jpg';
import Banner1 from '../../images/landingPage/WhoWeAre/Empowering-Entrepreneurs-Focus.jpg';
import WhyBusiness from '../../images/landingPage/home/WhyBusiness.jpg';
import JoinOurCommunity from '../../images/landingPage/home/JoinOurCommunity.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Landing.module.scss';
import { NavHashLink } from 'react-router-hash-link';
import Video from '../../images/landingPage/home/GSGFl.mp4';
import Modal from 'react-bootstrap/Modal';

function AboutUs() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

  return <>
    <Header />
    <section className={`${styles.section1} container-fluid`}>
      <div className={`container py-lg-5 py-xs-0`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>About</span> Us</h2>
            <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Entrepreneurship has proven to have a positive impact on today's global challenges.<br /> Each one of us, by developing and using our strengths and skills, are agents of social change.</p>
            <div className='col-sm-12' data-aos="fade-up"><NavHashLink to="/get-involved"  scroll={el => scrollWithOffset(el)}className='btn--primary me-2' role='button' aria-label='Get Involved'>Get Involved</NavHashLink> </div>
          </div>
          <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end ps-lg-5 ps-xs-0 pe-lg-5 pe-xs-0 position-relative rightEffect'>
            <img src={Banner} alt='Slider' className='w-100 height420 br--30' data-aos="fade-up"/>
          </div>
        </div>
      </div>
    </section>
    <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton className={`${styles.modalHeader} border-0 pb-0`}>
        </Modal.Header>
        <Modal.Body className='text-center p-0 d-flex'>
          <video controls autoPlay width="100%" className='br--5'>
            <source src={Video} type="video/webm"/>
            <source src={Video} type="video/mp4"/>
          </video>
        </Modal.Body>
    </Modal>
    <section className={`${styles.section5} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-12 col-xs-12 text-center pb-4 fontPoppins'>
            <div className={`bgGradient--primary py-4 br--999`}>
              <h3 className={`${styles.section5Inner} fs--44 lh-45 fw--600 text-white`} data-aos="fade-up">Go Startup Go Foundation aims at enabling as many formal SMEs as possible.</h3>
            </div>
          </div>
          <div className='col-sm-12 col-xs-12 text-center px-lg-5 px-xs-0 fontPoppins'>
            <p className='fs--18 mb-0 lh--35 text--white3 text-center px-lg-5 px-xs-0' data-aos="fade-up">Job saturation in the private sector and the shrinking public sector in Africa are driving the demand for the startup of the right kind of formal enterprises that will positively impact economic development and reduce poverty in sub-Saharan Africa.</p>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section6} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
            <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 mb-0' data-aos="fade-up">Empowering Entrepreneurs Focus</h3>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 mb-0 ' data-aos="fade-up">GoStartUpGo Foundation is a 501(c)(3) nonprofit dedicated to fostering entrepreneurship in developing countries. We empower individuals through hands-on training, ongoing mentorship, and access to funding that allows them to start and grow sustainable businesses. Our mission is not to provide temporary relief but to offer lasting solutions that help entrepreneurs thrive and create jobs in their communities.</p>
          </div>
          <div className='col-sm-6 col-xs-12  ps-lg-5 ps-xs-0'>
            <img src={Banner1} alt='Empowering Entrepreneurs Focus' className='w-100 h-auto br--10' data-aos="fade-right" />
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section4} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0'>
            <img src={WhyBusiness} alt='Our Approach' className='w-100 h-auto br--10' data-aos="fade-right" />
          </div>
          <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
            <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 mb-0' data-aos="fade-up">Our Approach</h3>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 mb-0 pe-lg-5 pe-xs-0' data-aos="fade-up">We believe in the strength of people. Every entrepreneur we support is ready to take the next step toward building something meaningful. GoStartUpGo equips them with the skills and resources they need to succeed, knowing that their success will ripple through families and communities.</p>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section10} container-fluid bgGradient--primary`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--30 mb-3 fontMontserrat text-white fw--600 lh-38' data-aos="fade-up">Go Start Up Go is the ideal partner to turn your small business dreams into reality.</h2>
            <p className='fs--16 text--grey1 mb-0 fontMontserrat lh-25' data-aos="fade-up">With Go Start Up Go, you'll have the support and tools necessary to be the best version of yourself and realize your full potential.</p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <Copyright />
  </>
}

export default AboutUs